import * as _ from 'lodash'
import {Alert, AlertType} from 'app/components/alert'
import {each, map} from "lodash";
import Tooltip from 'bootstrap/js/src/tooltip'
import * as Routes from 'routes'
import PartnerFilter from 'app/components/partner_filter'
import OrganizationFilter from 'app/components/organization_filter';
import {ResponseStatus} from 'app/constants/generals'
import {el, mount} from 'redom'
import * as Agent from 'superagent'

/**
 * Base class for Page
 */
export default class Page {
    protected baseUrl: string
    protected urlParams: URLSearchParams
    protected url: URL

    protected _alerts: Alert
    protected _pageAlerts: HTMLElement

    constructor() {
        this.baseUrl = '/'
        this.url = new URL(location.href)
        this.urlParams = this.url.searchParams

        this._pageAlerts = document.querySelector('.page-alerts') as HTMLElement
        this._alerts = new Alert(this._pageAlerts)
        this.recommendedNotifBanner()
    }

    /**
     * Allow only numeric on form inputs but allow 'Enter' key to
     * submit the form
     *
     * @param element
     */
    static inputNumericOnly(element) {
        element.onkeypress = (evt: KeyboardEvent) => {
            if (evt.key == 'Enter') return true
            // @ts-ignore
            return !isNaN(evt.key)
        }
    }

    /**
     * Allow only numeric or '+' on form inputs for phone number
     *
     * @param element
     */
    static inputPhoneNumberFormat(element) {
        element.onkeypress = (evt: KeyboardEvent) => {
            // @ts-ignore
            return !isNaN(evt.key) || (evt.keyCode == 43)
        }
    }

    /**
     * Get current URL origin
     *
     * == Example:
     * http://localhost:4000
     *
     * @protected
     */
    protected urlOrigin(): string {
        return this.url.origin
    }

    /**
     * Get current URL path
     *
     * == Example:
     * /support/email/backup
     *
     * @protected
     */
    protected urlPath(): string {
        if (!this.url.pathname.length) return

        return this.url.pathname.replace(/\/+$/, '')
    }

    /**
     * Construct URL from pathname and params
     *
     * == Example:
     * `createUrl(this.currentUrlPath(), this.currentUrlParams())`
     *
     * @param pathname
     * @param params
     *
     * @protected
     */
    protected createUrl(pathname: string, params: string): string {
        let url = new URL(pathname, this.urlOrigin())
        url.search = params

        return url.href
    }

    /**
     * Get segment of current URL based on position
     *
     * == Example:
     * urlSegment(1)
     * /supports/email/backup => backup
     *
     * @protected
     */
    protected urlSegment(num: number) {
        if (!this.url.pathname.length) return

        return _.chain(this.url.pathname).split('/').nth(num).value()
    }

    protected urlParamsValue(key) {
        return this.urlParams.get(key)
    }

    protected static redirect(url: string) {
        location.href = url
    }

    protected showMessage() {
        let titleElement = document.querySelector('#alert-title') as HTMLElement,
            contentElement = document.querySelector('#alert-content') as HTMLElement,
            typeElement = document.querySelector('#alert-type') as HTMLElement

        if (titleElement && contentElement && typeElement) {
            this._alerts.add({
                title: titleElement.innerText,
                content: contentElement.innerText,
                type: typeElement.innerText as AlertType,
            })
            titleElement.innerHTML = ''
            contentElement.innerHTML = ''
            typeElement.innerHTML = ''
        }
    }

    private recommendedNotifBanner() {
        let recommendedNotifBanner = document.querySelector('.recommended-notif-warning-banner') as HTMLElement
        if (recommendedNotifBanner == undefined)
            return
        let dontRemindMeAgainBtns = recommendedNotifBanner.querySelectorAll('.dont-remind-me-again-btn') as NodeListOf<HTMLElement>

        dontRemindMeAgainBtns.forEach((element) => {
            element.onclick = (event) => {
                let notif_params = {}
                notif_params[element.dataset.key] = element.dataset.value != null
                
                Agent.put(Routes.notification_settings_dont_remind_me_path())
                    .send({ 
                        authenticity_token: Page.csrfToken(),
                        notification_params: notif_params
                    })
                    .on('error', (error) => {
                        location.reload()
                    })
                    .then((response) => {
                        let alertElement = recommendedNotifBanner.querySelector("[data-key='"+response.body.key+"'").closest('.alert') as HTMLElement
                        alertElement.hidden = true
                    })
            }
        })
    }

    /**
     * Get Rails CSRF token from the page meta tags
     *
     * @protected
     */
    public static csrfToken() {
        const csrfTokenEl = document.querySelector('[name="csrf-token"]') as HTMLMetaElement
        if (!csrfTokenEl) return null

        return csrfTokenEl.content
    }

    protected reloadPage(delay: number = 2000) {
        setTimeout(() => {
            location.reload()
        }, delay)
    }

    protected initTooltips() {
        return each(document.querySelectorAll('[data-bs-toggle="tooltip"]'), (el) => {
            return new Tooltip(el, {html: true})
        })
    }

    protected initPartnerFilter(container: HTMLElement, url: string = Routes.partners_list_path('json'), options: Object = {}) {
        let partnerFilter = new PartnerFilter(
            container,
            url,
            null,
            options
        )

        partnerFilter.onError((error) => {
            if (error.response.status == ResponseStatus.SESSION_TIMEOUT) {
                Page.redirect(Routes.sign_in_path())
            }
        })
        partnerFilter.onChange((activeFilters) => {
            let allPartner = document.querySelector('.filter-partner .select-all input') as HTMLInputElement
            let partners = map(activeFilters, function (item) {
                return item.id
            })

            const url = new URL(window.location.href)

            Agent
                .put(Routes.set_selected_partners_path())
                .send({
                    partners: partners,
                    all_partner: allPartner.checked,
                    path: url.pathname,
                    authenticity_token: Page.csrfToken()
                })
                .then((response) => {
                    location.reload()
                })
                .catch((err) => {
                    console.log(err)
                })
        })

        return partnerFilter
    }

    protected initOrgFilter(container: HTMLElement, url: string = Routes.organizations_list_path('json'), options: Object = {}) {
        let orgFilter = new OrganizationFilter(
            container,
            url,
            null,
            options
        )

        orgFilter.onError((error) => {
            if (error.response.status == ResponseStatus.SESSION_TIMEOUT) {
                Page.redirect(Routes.sign_in_path())
            }
        })

        orgFilter.onChange((activeFilters) => {
            let organizations = map(activeFilters, function (item) {
                return item.id
            })

            const url = new URL(window.location.href)

            Agent
                .put(Routes.set_selected_organizations_path())
                .send({
                    organizations: organizations,
                    path: url.pathname,
                    authenticity_token: Page.csrfToken()
                })
                .then((response) => {
                    location.reload()
                })
                .catch((err) => {
                    console.log(err)
                })
        })

        return orgFilter
    }

    protected renderInputErrors(input: HTMLInputElement, message: string) {
        let parent = input.closest('.form-group'),
            messages = parent.querySelector('.invalid-feedback')

        input.classList.remove('is-invalid')
        if (messages) messages.remove()

        input.classList.add('is-invalid')
        mount(parent, el('.invalid-feedback', message))
    }

    protected resetInputErrors(input: HTMLInputElement) {
        let parent = input.closest('.form-group'),
            messages = parent.querySelector('.invalid-feedback')

        input.classList.remove('is-invalid')
        if (messages) messages.remove()
    }
}